<template>
  <div class="general__page">
    <v-row>
      <v-col class="page-header" cols="12">
        <h2 class="page-title">Notifications</h2>
      </v-col>
      <v-divider class="ml-3"></v-divider>
      <v-col cols="12" v-if="isLoading">
        <SkeletonLoaders type="card-heading, list-item-three-line" />
      </v-col>
      <v-col cols="12" v-else>
        <Telegram />
        <div class="d-flex justify-end mt-4">
          <v-btn
            :disabled="isLoadingAction || isLoading"
            :loading="isLoadingAction || isLoading"
            @click="submit"
            color="primary"
            depressed
            >Save</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Telegram from './components/Telegram.vue';
import { settingsApi } from '@/apis/settings';
import event from '@/plugins/event-bus';
export default {
  components: {
    Telegram,
  },
  data() {
    return {
      general: {
        storeDetail: {
          name: '',
          accountEmail: '',
          customerEmail: '',
          telegramGroupIds: '',
          telegramJob: { newOrder: true, newAbandonedCheckout: false, storeError: false },
        },
        language: 'en',
        addressLegalName: '',
        addressPhone: '',
        address: '',
        addressAppartment: '',
        addressCity: '',
        addressCountry: '',
        addressPostalCode: '',
        standardTimezone: '',
        standardUnitWeight: '',
        standardDefaultWeightUnit: '',
        editOrderIdPrfeix: '',
        editOrderIdSuffix: '',
        currency: '',
        currencyFormatHtmlWithoutCurrency: '',
        currencyEmailWithoutCurrency: '',
        blacklistStates: [],
      },
      isLoading: false,
      isLoadingAction: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      try {
        let { data } = await settingsApi.getGeneral();
        this.general = data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async submit() {
      this.isLoadingAction = true;
      try {
        let { data } = await settingsApi.updateGeneral(this.general);
        switch (data.language) {
          case 'vi':
            localStorage.setItem('currencySign', 'đ');
            localStorage.setItem('currencyCode', 'VND');
            break;
          case 'en':
            localStorage.setItem('currencySign', '$');
            localStorage.setItem('currencyCode', 'USD');
            break;
          case 'eur':
            localStorage.setItem('currencySign', '€');
            localStorage.setItem('currencyCode', 'EUR');
            break;
        }

        this.$store.commit('setPagePreferrence', data);
        event.$emit('getStepStatus', message => {
          this.getStep();
        });
        this.isLoadingAction = false;
      } catch (error) {
        this.isLoadingAction = false;
      }
    },
  },
};
</script>
